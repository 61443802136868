import React from "react";
import { useLocation } from "react-router-dom";
import { checkIsNonHospitalityBusiness, getClaimBusinessUrl } from "utils/business";
import useTrackEvent from "hooks/useTrackEvent";
import { EVENTS } from "utils/constants/events";
import { ReactComponent as ArrowRight } from "assets/fillableIcons/arrow-back.svg";
import styles from "./ClaimBusinessBanner.module.css";

const ClaimBusinessBanner = ({ locationData }) => {
  const isNonHospitality = checkIsNonHospitalityBusiness(locationData);

  const trackEvent = useTrackEvent();
  const { pathname } = useLocation();
  const { type, url } = getClaimBusinessUrl(isNonHospitality, pathname, locationData, true);

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <span className={styles.text}>
          Claim your businesses on Atmosfy today to connect with locals in your area
        </span>
        <a
          onClick={() => trackEvent(`${EVENTS.WEB_Claim_Business_Link}_${type}`, { url })}
          href={url}
          target="_blank"
          rel="noreferrer">
          <button className={styles.claimBusinessButton}>
            <span className={styles.linkText}>Claim Business</span>
            <ArrowRight width="20" height="20" fill='#04060C' />
          </button>
        </a>
      </div>
    </div>
  )
}

export default ClaimBusinessBanner;
