import DEBUG_FLAG_DATA from "./mocks/posthogDebugFlagData.json";

const flagDataElement = document.getElementById("page-flag-data");
const flagConfig =
  process.env.NODE_ENV === "development"
    ? DEBUG_FLAG_DATA
    : JSON.parse(flagDataElement.textContent);

export const posthog_apiKey = window.location.host === "atmosfy.io"
  ? "phc_vnyiRZ9kCEZGdhREpFX86komQwucyRQci5NUBsC1Lh7"
  : "phc_qWDfWIhM1j7cg3Un2XOPj2wxjNwfjC5ykBpSipzqzZb";

export const posthogConfig = {
  api_host: "https://app.posthog.com",
  disable_session_recording: true,
  persistence: "localStorage+cookie",
  advanced_disable_feature_flags_on_first_load: true
}
if (!!flagConfig && !!flagConfig.distinct_id && !!flagConfig.flags) {
  posthogConfig.bootstrap = {
    distinctID: flagConfig.distinct_id,
    isIdentifiedID: true,
    featureFlags: flagConfig.flags
  };
}
