import TiktokPixel from "tiktok-pixel";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

const isBizPage = window.location.pathname.includes("/biz");

const globalClickHandler = (evt) => {
  installButtonClickHandler(evt);
  bizOwnersClickHandler(evt);
  claimBusinessClickHandler(evt);
  weFunderClickHandler(evt);
};

const installButtonClickHandler = (evt) => {
  if (
    !evt.target.firstChild ||
    (evt.target.firstChild?.nodeValue !== "Get Atmosfy" &&
      evt.target.firstChild?.nodeValue !== "Install Now" &&
      evt.target.firstChild?.nodeValue !== "Open")
  ) {
    return;
  }

  trackInstall();
};

const trackInstall = () => {
  TiktokPixel.track("ClickButton", {
    content_type: "product",
    description: "Install-Button-Clicked"
  });
};

const bizOwnersClickHandler = (evt) => {
  if (
    !evt.target.firstChild ||
    evt.target.firstChild?.nodeValue !== "Biz Owners"
  ) {
    return;
  }

  ReactGA.event({
    category: "Marketing",
    action: "biz-owners-click",
    label: "Clicked Biz Owners Navigation Link"
  });
};

const claimBusinessClickHandler = (evt) => {
  if (
    !evt.target.firstChild &&
    evt.target.firstChild?.nodeValue !== "Claim Business" &&
    evt.target.firstChild?.nodeValue !== "Claim this business" &&
    evt.target.firstChild?.nodeValue !== "Claim your business" &&
    evt.target.firstChild?.nodeValue !== "Claim $250"
  ) {
    return;
  }

  if (isBizPage) {
    const bizClaimed = "ClaimBusinessClicked";

    TiktokPixel.track("AddToCart", {
      contents: [
        {
          description: "/biz/claim-business",
          content_id: bizClaimed,
          quantity: 1
        }
      ],
      content_type: "product"
    });

    ReactPixel.fbq("track", "AddToCart", { content_ids: [bizClaimed] });
  }

  ReactGA.event({
    category: "Marketing",
    action: "claim-business-click",
    label: "Clicked claim your business link"
  });
};

const weFunderClickHandler = (evt) => {
  if (!evt.target && !evt.target.textContent && evt.target.textContent.toLowerCase() !== "join the team") {
    return;
  }

  TiktokPixel.track("InitiateCheckout", {
    content_type: "product",
    description: "Join-The-Team-Clicked"
  });

  ReactPixel.fbq("track", "InitiateCheckout");
};

export { globalClickHandler, trackInstall };
