import {getReferralLink} from "./getReferralLink";
import {posthogConfig} from "./posthogUtils";
import {ATMOSFY_ONE_LINK} from "./constants";

const APP_STORE_LINK = "https://atmosfy.onelink.me/QSMV/mw";
const SINGLE_TAP_LINK ="https://delivers.dtignite.com/v2/delivers/clickAd.jsp?siteId=14530&campaignId=43462&tsti=true&triggerSource=delivers&updateApp=false&enablePostInstallAutoLaunch=true&isDT=%5BELIGIBILITY%5D&dvURL=https%3A%2F%2Fatmosfy.onelink.me%2FQSMV%3Fpid%3Dmobile-web%26c%3Dsingletap%7C";

const parameterMap = {
  "utm_source": "af_sub3",
  "utm_medium": "af_channel",
  "utm_campaign": "c",
  "utm_content": "af_ad"
}

const getOneLink = (useSingleTap = false) => {
  const referralLink = getReferralLink();
  if (referralLink) {
    return referralLink;
  }

  const queryParams = {
    "deep_link_sub10": posthogConfig.bootstrap.distinctID
  };

  const oneLink = new URL(APP_STORE_LINK);

  const urlSearchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of urlSearchParams.entries()) {
    const lowerKey = key.toLowerCase();
    if (!parameterMap[lowerKey]) {
      continue;
    }

    oneLink.searchParams.set(parameterMap[lowerKey], value);
  }

  oneLink.searchParams.set("deep_link_sub10", posthogConfig.bootstrap.distinctID);
  oneLink.searchParams.set("af_sub1", encodeURI(window.location.toString()));

  // disabling singletap link as it is not allowing us to track conversion/attribution
  // if (useSingleTap) {
  //   const singleTapUrl = new URL(SINGLE_TAP_LINK);
  //   singleTapUrl.searchParams.set('dvURL', encodeURI(oneLink.toString()));
  //
  //   return singleTapUrl.toString();
  // }

  return oneLink.toString();
}

const getOneLinkWithClickSource = (clickSource) => {
  const url = new URL(ATMOSFY_ONE_LINK);

  if (!clickSource || clickSource.length === 0) {
    return ATMOSFY_ONE_LINK;
  }

  url.searchParams.set("af_sub2", clickSource);
  return url.toString();
}

export {
  getOneLink,
  getOneLinkWithClickSource
};
