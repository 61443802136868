import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  signOut as _signOut,
  OAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA0N4f6v739kGSVoiVvowwGkyzUwpC-ptk",
  authDomain: "atmosfy-cd809.firebaseapp.com",
  databaseURL: "https://atmosfy-cd809.firebaseio.com",
  projectId: "atmosfy-cd809",
  storageBucket: "atmosfy-cd809.appspot.com",
  messagingSenderId: "59641197431",
  appId: "1:59641197431:web:4c14271af265c437522772"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Google
const googleSignInWithPopup = async () => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account"
  });

  try {
    const result = await signInWithPopup(auth, provider);
    return { user: result?.user }
  } catch (error) {
    console.error("Error signing in with Google", error)
    return Promise.reject(error);
  }
}

// Facebook
const facebookSignInWithPopup = async () => {
  const provider = new FacebookAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    return { user: result?.user }
  } catch (error) {
    console.error("Error signing in with Facebook", error)
    return Promise.reject(error);
  }
}

// Apple
const appleSignInWithPopup = async () => {
  const provider = new OAuthProvider("apple.com");
  provider.addScope("email");
  provider.addScope("name");

  try {
    const result = await signInWithPopup(auth, provider);
    return { user: result?.user }
  } catch (error) {
    console.error("Error signing in with Apple", error)
    return Promise.reject(error);
  }
}

const getCurrentUserToken = () => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    return Promise.resolve(null);
  }
  return currentUser.getIdToken();
}

const signOut = () => {
  return _signOut(auth);
}

export {
  appleSignInWithPopup,
  facebookSignInWithPopup,
  googleSignInWithPopup,
  getCurrentUserToken,
  signOut
};
