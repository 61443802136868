import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import isEmpty from "lodash/isEmpty";
import { checkIsHospitalityBusiness, checkIsNonHospitalityBusiness } from "./business";

const PostHogPageView = ({ locationData }) => {
  const { pathname, search } = useLocation();
  const posthog = usePostHog();

  const trackLocationView =
    pathname.includes("/location/") ||
    pathname.includes("/locations/") ||
    pathname.includes("/reviews/") ||
    pathname.includes("/listing/")

  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname
      if (search.toString()) {
        url = url + `?${search.toString()}`
      }

      if (trackLocationView) {
        if (isEmpty(locationData)) {
          return;
        }

        const isHospitality = checkIsHospitalityBusiness(locationData);
        const isNonHospitality = checkIsNonHospitalityBusiness(locationData);

        posthog.capture(
          "$pageview",
          {
            "$current_url": url,
            "locationId": locationData.clm_id,
            "isHospitality": isHospitality,
            "isNonHospitality": isNonHospitality
          }
        )
        return;
      }

      posthog.capture(
        "$pageview",
        {
          "$current_url": url
        }
      )
    }
  }, [pathname, search, posthog, locationData])

  return null
}

export default PostHogPageView;