import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext
} from "react";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { useLocation } from "react-router-dom";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { SINGLE_TAP_LINK } from "utils/constants";
import {
  APP_MODAL_TRIGGER,
  INSTALL_MODAL_VARIATIONS,
  VARIANT,
  VARIANT_A,
  VARIANT_B,
  VARIANT_C,
  POSTHOG_WAIT_TIME
} from "utils/featureFlags";
import { ModalContext } from "components/Modal/ModalContext";
import atmosfyLogo from "images/atmosfy-logo.svg";
import ModalFriendsImage from "assets/images/modal-friends.png";
import "./WatchFeedModal.css";


// TODO: Refactor Routes and layouts to remove this check
const NO_OPEN_ON_LOAD_FOR = [
  "/scholarship",
  "/kygo-sweepstakes",
  "/kygo-sweepstakes-rules",
  "/creators",
  "/communityround"
];

const titleStyles = {
  fontFamily: "proxima-nova",
  fontSize: "19px",
  fontWeight: "600",
  textAlign: "center",
  marginTop: "0",
  marginBottom: "0"
};

const descriptionStyles = {
  fontFamily: "proxima-nova",
  fontSize: "16px",
  fontWeight: "400",
  textAlign: "center",
  marginTop: "8px",
  marginBottom: "24px"
};

const WatchFeedModal = (props) => {
  const [millis, setMillis] = useState(0);

  const { isActive, openModal, closeModal } = useContext(ModalContext);
  const { pathname } = useLocation();

  const appModalTriggerTest =
    useFeatureFlagVariantKey(APP_MODAL_TRIGGER);
  const installModalVariationsTest =
    useFeatureFlagVariantKey(INSTALL_MODAL_VARIATIONS);

  let modalClass = isActive ? "modal active" : "modal";

  useEffect(() => {
    if (millis > POSTHOG_WAIT_TIME) {
      return;
    }

    const interval = setInterval(() => {
      setMillis(millis + 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [millis]);

  const shouldCheckForModal = useMemo(() => {
    return appModalTriggerTest || millis > POSTHOG_WAIT_TIME;
  }, [appModalTriggerTest, millis]);

  useEffect(() => {
    if (
      NO_OPEN_ON_LOAD_FOR.includes(pathname) ||
      !shouldCheckForModal ||
      appModalTriggerTest === VARIANT
    ) {
      return;
    }
    openModal();
  }, [
    openModal,
    pathname,
    shouldCheckForModal,
    appModalTriggerTest
  ]);

  const handleClickCta = useCallback((ev) => {
    ReactGA.event({
      category: "Modal",
      action: "Tapped Open Atmosfy in Modal"
    });
    ReactPixel.trackCustom("MW_WATCH_Modal_Open_Atmosfy");

    window.open(SINGLE_TAP_LINK);
  }, []);

  const handleClickNotNow = useCallback(
    (ev) => {
      ev.stopPropagation();
      ReactGA.event({
        category: "Modal",
        action: "Tapped Not Now in Modal"
      });
      ReactPixel.trackCustom("MW_WATCH_Modal_Not_Now");

      closeModal();
    },
    [closeModal]
  );

  const { title, description } = useMemo(() => {
    let title;
    let description;

    switch (installModalVariationsTest) {
      case VARIANT_A:
        title = "Sign up to get cash back on your\nfavorite restaurants";
        description = "Enjoy $5 cash back on restaurants in your\narea. Sign up & claim your reward on the\nAtmosfy app.";
        break;
      case VARIANT_B:
        title = "Sign up to see where\nyour friends are going!";
        description = "See the places your friends love going to\nand get notified when they visit a new place.";
        break;
      case VARIANT_C:
        title = "Sign up to get exclusive deals\nin your city";
        description = "Sign up to Atmosfy to get access to exclusive\ndeals and discounts in local restaurants.";
        break;
      default:
        title = "Get the full app experience";
        description = "Enjoy more videos and great features on the\nAtmosfy app.";
    }

    return {
      title,
      description
    }
  }, [installModalVariationsTest]);

  const imgProps = useMemo(() => {
    if (installModalVariationsTest === VARIANT_B) {
      return {
        src: ModalFriendsImage,
        alt: "Friends image"
      };
    }

    return {
      src: atmosfyLogo,
      alt: "Atmosfy logo"
    };
  }, [installModalVariationsTest])

  // Todo: (Danni) Once we complete installModalVariationsTest, we can remove this check
  if (millis < 1000) {
    return null;
  }

  return (
    <div className={modalClass}>
      <div className="modal__background" />
      <div className="modal__content">
        <div className={
          `${installModalVariationsTest === VARIANT_B ? "modal__friends_logo" : "modal__logo"}`
        }>
          <img {...imgProps} loading="lazy" />
        </div>

        <pre style={titleStyles}>{title}</pre>
        <pre style={descriptionStyles} className="modal__description">{description}</pre>

        <button onClick={handleClickCta} className="button__cta button__label">
          Install Now
        </button>

        <button
          className="button__label-tight button__not-now"
          onClick={handleClickNotNow}
        >
          Not Now
        </button>
      </div>
    </div>
  );
};

export default WatchFeedModal;
