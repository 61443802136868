import { usePostHog } from "posthog-js/react"

const useTrackEvent = () => {
  const posthog = usePostHog();

  return (name, eventProperties) => {
    if (!name) return;

    posthog?.capture(name, eventProperties)
  };
};

export default useTrackEvent;
