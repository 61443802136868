import { getDeviceUniqueId } from "./getDeviceUniqueId";
import { getEnvironment, Environments } from "./environment";
import Cookies from "js-cookie";

const getDefaultRequestHeaders = (bearerToken) => {
  const authToken = Cookies.get("app-token");
  const deviceId = getDeviceUniqueId();
  const headers = {
    "Content-Type": "application/json",
    "X-DEVICE-ID": deviceId
  };

  if (getEnvironment() !== Environments.Sandbox) {
    headers["x-app-version"] = "0.0.0.0";
  }

  if (authToken) {
    headers["Auth-Token"] = authToken;
  }

  if (bearerToken) {
    headers["Authorization"] = `Bearer ${bearerToken}`;
  }

  return headers;
};

export { getDefaultRequestHeaders };
