import { HOSPITALITY_SUPER_CATEGORIES } from "./review";

export const ChargeBeeUrl = "https://atmosfy.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Atmosfy-Profile-Purchase-monthly-USD-Monthly&subscription_items[quantity][0]=1&layout=full_page";
export const SalesContactUrl = "https://calendly.com/seth-atmosfy/atmosfy-business-verification";
export const HospitalityBizUrl = "https://atmosfybiz.io/claimbizprofile";

export const getClaimBusinessUrl = (isNonHospitality, source, locationData = null, hospitalityBizEnabled) => {
  if (hospitalityBizEnabled) {
    const isHospitalityBusiness = checkIsHospitalityBusiness(locationData);
    const isCanonical = ["", "/", "canonical-mobile"].includes(source);

    if (isHospitalityBusiness || isCanonical) {
      return { type: "HOSPITALITY_BIZ", url: HospitalityBizUrl };
    }
  }

  if (isNonHospitality) {
    return { type: "NON_HOSPITALITY_BIZ", url: `/verify-business${source ? `?utm_source=${source}` : ""}` };
  }

  return { type: "DEFAULT", url: formatClaimBusinessUrl(source, locationData) };
};

export const checkIsNonHospitalityBusiness = locationData=> {
  if (!locationData) {
    return false;
  }

  const { super_categories, tags } = locationData || {};
  return super_categories?.includes("shopping") ||
    super_categories?.length === 0 ||
    tags?.categories?.some(({ safe_name }) => ["business-and-professional-services", "health-and-medicine"].includes(safe_name));
}

export const checkIsHospitalityBusiness = locationData => {
  if (!locationData) {
    return false;
  }

  if (checkIsNonHospitalityBusiness(locationData)) {
    return false;
  }

  const { super_categories, tags } = locationData || {};
  const { cuisines, food_and_drink } = tags || {};

  return HOSPITALITY_SUPER_CATEGORIES.some(superCategory => super_categories?.includes(superCategory)) ||
    cuisines?.length > 0 ||
    food_and_drink?.length > 0;
}

export const formatClaimBusinessUrl = (path, location) => {
  if (!path) {
    return "/claim-business";
  }

  const { clm_id, clm_label, clm_name, clm_city, clm_address, clm_state, clm_zip, clm_prompt, links } = location ?? {};
  const sourceSearch = clm_prompt ? `?${clm_prompt}-${clm_id}` : "";
  const businessName = clm_name ?? clm_label;

  const sourceParam = `utm_source=${path}${sourceSearch}`;
  const positionParam = "position=Owner";
  const nameParam = businessName ? `businessName=${encodeURIComponent(businessName)}` : "";
  const websiteParam = links?.website ? `businessWebsite=${links?.website}` : "";
  const addressParam = clm_address ? `address[addr_line1]=${encodeURIComponent(clm_address)}` : "";
  const cityParam = clm_city ? `address[city]=${encodeURIComponent(clm_city)}` : "";
  const stateParam = clm_state ? `address[state]=${encodeURIComponent(clm_state)}` : "";
  const zipParam = clm_zip ? `address[postal]=${encodeURIComponent(clm_zip)}` : "";

  const search = [
    sourceParam,
    positionParam,
    nameParam,
    websiteParam,
    addressParam,
    cityParam,
    stateParam,
    zipParam
  ].filter(Boolean).join("&");

  return `/claim-business?${search}`;
}
