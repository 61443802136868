import React from "react";
import Logo from "images/logo-pink.svg";
import styles from "./ErrorBoundary.module.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log("ErrorBoundary", error);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }

      return (
        <div className={styles.container}>
          <img className={styles.logo} src={Logo} alt="Atmosfy logo" />

          <h4 className={styles.contentText}>
            Oops, looks like something went wrong. Let's try this again.
          </h4>

          <button className={styles.retryButton}>
            <a href="/" rel="noreferrer">
              Retry
            </a>
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;