/** A/B Tests */
export const APP_MODAL_TRIGGER = "web-app-modal-trigger";
export const APP_MODAL_REMOVAL = "web-app-modal-removal";
export const INSTALL_MODAL_VARIATIONS = "web-install-modal-variations";
export const LOCATION_PROFILE = "web-location-profile";
export const LOCATION_PROFILE_EMPTY = "web-location-profile-empty";
export const ONBOARDING_OVERLAY = "web-onboarding-overlay";
export const VIDEO_LIMIT = "web-video-limit";
export const WATCH_NEAR_ME = "web-watch-near-me";
export const WATCH_VIDEO_SELECTION_V2_US = "web-watch-video-selection-V2-us";

/** Feature Flags */
export const CONTROL = "control";
export const VARIANT = "variant";
export const VARIANT_A = "variant_a";
export const VARIANT_B = "variant_b";
export const VARIANT_C = "variant_c";
export const VARIANT_D = "variant_d";

export const POSTHOG_WAIT_TIME = 2000;

