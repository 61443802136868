import styles from "./Footer.module.css";
import atmosfyNewLogo from "../../images/atmosfy-new-logo.svg";
import iconFb from "../../images/icon-fb.svg";
import iconInstagram from "../../images/icon-instagram.svg";
import iconLinkedin from "../../images/icon-linkedin.svg";
import iconTikTok from "../../images/icon-tik-tok.svg";

import { ATMOSFY_ONE_LINK } from "utils/constants";

const MarketingFooter = ({ containerStyle }) => {
  return (
    <div className={styles.container}  style={containerStyle}>
      <div className={styles.contentContainer}>
        <div className={styles.linkContainer}>
          <div className={styles.linkSection}>
            <div className={styles.linkHeader}>Atmosfy</div>
            <a
              href="https://apps.apple.com/US/app/id1472693426?mt=8"
              target="_blank"
              rel="noreferrer"
            >
              Product
            </a>
            <a href="/creators" target="_blank" rel="noreferrer">
              Become a Creator
            </a>
            <a
              href="/biz"
              target="_blank"
              rel="noreferrer"
            >
              For Biz Owners
            </a>
            <a
              href="https://qr.atmosfy.io/9HGU"
              target="_blank"
              rel="noreferrer"
            >
              Brand Ambassadors
            </a>
            <a
              className={styles.getAtmosfy}
              href={ATMOSFY_ONE_LINK}
              target="_blank"
              rel="noreferrer noopener"
            >
              Get Atmosfy
            </a>
          </div>

          <div className={styles.linkSection}>
            <div className={styles.linkHeader}>Resources</div>
            <a
              href="https://www.linkedin.com/company/atmosfyapp/jobs/"
              target="_blank"
              rel="noreferrer"
            >
              Careers — <span style={{ color: "#93CB9A" }}>we're hiring!</span>
            </a>
            <a href="mailto:press@atmosfy.io">Press Kit</a>
            <a
              href="https://atmosfy.io/terms-of-service.html"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
            <a
              href="https://atmosfy.io/privacy-policy.html"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href="https://atmosfy.io/support-request.html"
              target="_blank"
              rel="noreferrer"
            >
              Customer Support
            </a>
          </div>

          <div className={styles.linkSection}>
            <div className={styles.linkHeader}>Community</div>
            <a href="/scholarship" target="_blank" rel="noreferrer">
              Atmosfy Veteran Scholarship
            </a>
            <a href="/kygo-sweepstakes" target="_blank" rel="noreferrer">
              Kygo Giveaway
            </a>
          </div>

          <div className={styles.linkSection}>
            <div className={styles.linkHeader}>Trending Restaurants</div>
            <a href="/location/houston/the-pit-room-264710">The Pit Room</a>
            <a href="/location/philadelphia/zahav">Zahav</a>
            <a href="/location/las-vegas/the-coffee-class-204421">
              The Coffee Class
            </a>
            <a href="/location/new-york/summit-one-vanderbilt">
              Summit One Vanderbilt
            </a>
            <a href="/location/brooklyn/roberta-s-172108">Roberta's</a>
            <a href="/location/los-angeles/girl-and-the-goat">
              Girl & The Goat LA
            </a>
            <a href="/location/philadelphia/suraya">Suraya</a>
            <a href="/location/new-york/jacobs-pickles">Jacob's Pickles</a>
            <a href="/location/new-york/thai-diner-148433">Thai Diner</a>
            <a href="/location/chicago/3-arts-club-caf">3 Arts Club Cafe</a>
            <a href="/location/san-francisco/daeho-korean-bbq">Daeho</a>
            <a href="/location/anaheim/disneyland-park">Disneyland Park</a>
            <a href="/location/washington/le-diplomate-248840">Le Diplomate</a>
            <a href="/location/san-diego/balboa-park">Balboa Park</a>
            <a href="/location/buena-park/porto-s-bakery-and-cafe-108376">
              Porto's Bakery and Cafe
            </a>
          </div>

          <div className={styles.socials}>
            <div className={styles.atmosfyLogoContainer}>
              <img
                src={atmosfyNewLogo}
                alt="Atmosfy logo"
                className={styles.logo}
                loading="lazy"
              />
            </div>
            <div className={styles.externalSocial}>
              <div>
                <img
                  src={iconFb}
                  alt="Facebook Logo"
                  className={styles.iconFB}
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/atmosfyapp",
                      "_blank"
                    );
                  }}
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src={iconInstagram}
                  alt="Instagram Logo"
                  className={styles.iconInstagram}
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/atmosfyapp",
                      "_blank"
                    );
                  }}
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src={iconLinkedin}
                  alt="Linkedin Logo"
                  className={styles.iconLinkedin}
                  onClick={() => {
                    window.open(
                      "https://www.linkedin.com/company/atmosfyapp",
                      "_blank"
                    );
                  }}
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src={iconTikTok}
                  alt="TikTok Logo"
                  className={styles.iconTikTok}
                  onClick={() => {
                    window.open(
                      "https://www.tiktok.com/@atmosfyit?lang=en",
                      "_blank"
                    );
                  }}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.copyrightText}>
          © 2024 Atmosfy, Inc. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default MarketingFooter;
