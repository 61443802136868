import Cookies from "js-cookie";
import {posthogConfig} from "./posthogUtils";

const getReferralLink = () => {
  let referralLink = Cookies.get("referralLink");
  if (!referralLink) {
    return referralLink;
  }

  return `${referralLink}&deep_link_sub10=${posthogConfig.bootstrap.distinctID}`;
};

export { getReferralLink };
