import {useState, useEffect, useContext} from "react";
import { getOneLinkWithClickSource } from "utils/getOneLink";
import AtmosfyLogo from "images/atmosfy-logo.svg";
import styles from "./StickyFooter.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {ModalContext} from "../Modal/ModalContext";

/**
 * As of 06/19/2024, this component is designed to be used on Desktop only.
 * */
const StickyFooter = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { isMobile } = useWindowDimensions();
  const { isActive } = useContext(ModalContext);

  useEffect(() => {
    setIsVisible(!isActive && !isMobile)
  }, [isActive, isMobile]);


  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <img src={AtmosfyLogo} alt="Atmosfy Logo" className={styles.logo}/>
      <div className={styles.contentContainer}>
        <p className={`body__bold ${styles.text}`}>
          Get the full app experience
        </p>
        <p className={`body__tight ${styles.text}`}>
          Enjoy more videos and great features on the Atmosfy app.
        </p>
      </div>
      <a
        href={getOneLinkWithClickSource("sticky-footer")}
        target="_blank"
        rel="noreferrer"
      >
        <button
          className="button__cta button__label"
          style={{
            padding: 0,
            marginBottom: 0,
            width: "153px",
            height: "40px"
          }}>
          Install Now
        </button>
      </a>
    </div>
  )
}

export default StickyFooter;