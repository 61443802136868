import omit from "lodash/omit";

export const REVIEW_READ_THRESHOLD_IN_SECONDS = 1;

export const VIDEO_ASPECT_RATIO = 9 / 16;

export const hasTag = (searchTag, tags) => {
  if (!tags || tags.length === 0) {
    return false;
  }
  return tags.some(
    (tag) => tag["clm_prompt"].toLocaleLowerCase() === searchTag
  );
};

export const SUPER_CATEGORY_LABEL = {
  dining: "Food & Drink",
  nightlife: "Nightlife",
  hotel: "Hotels",
  experiences: "Experiences",
  shopping: "Shopping"
}

export const HOSPITALITY_SUPER_CATEGORIES = Object.keys(omit(SUPER_CATEGORY_LABEL, "shopping"));
