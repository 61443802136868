import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";
import { posthog_apiKey, posthogConfig } from "utils/posthogUtils";
import PostHogPageView from "utils/PostHogPageView";
import App from "./App";
import { AuthContextProvider } from "components/Auth/AuthContext";
import { ModalContextProvider } from "./components/Modal/ModalContext";
import { HeaderContextProvider } from "./components/Header/HeaderContext";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <ModalContextProvider>
      <HeaderContextProvider>
        <PostHogProvider
          apiKey={posthog_apiKey}
          options={posthogConfig}
        >
          <BrowserRouter>
            <App />
            <PostHogPageView />
          </BrowserRouter>
        </PostHogProvider>
      </HeaderContextProvider>
    </ModalContextProvider>
  </AuthContextProvider>
);
