import React from "react";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "hooks/useWindowDimensions";
import Navigation from "components/MarketingHeader/Navigation";
import Footer from "components/Footer/Footer";

const DEFAULT_LAYOUT_ROUTES = [
  "/biz",
  "/biz/claim-business",
  "/claim-business",
  "/claim-business/complete"
];

const COMPACT_LAYOUT_ROUTES = [
  "/biz/sign-in"
];

const BusinessLandingPageLayout = ({ children }) => {
  const { pathname} = useLocation();
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const isCompactLayout = COMPACT_LAYOUT_ROUTES?.includes(pathname);
  const isDefaultLayout = DEFAULT_LAYOUT_ROUTES?.includes(pathname);

  const renderDefaultNavigation = () => {
    if (isCompactLayout && !isMobile && !isTablet) {
      return null;
    }

    if (isDefaultLayout) {
      return <Navigation visibleOnMobile />
    }

    return null;
  }

  return (
    <main className="App">
      {renderDefaultNavigation()}
      {children}
      {isDefaultLayout && <Footer/>}
    </main>
  );
};

export default BusinessLandingPageLayout;
