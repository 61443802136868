import { v4 as uuidv4 } from "uuid";

let deviceUniqueId = null;

const getDeviceUniqueId = () => {
  if (deviceUniqueId) {
    return deviceUniqueId;
  }

  deviceUniqueId = localStorage.getItem("deviceId");

  // if id does not exist, create and store it
  if (!deviceUniqueId) {
    const guid = uuidv4();
    deviceUniqueId = `web-${guid}`;
    localStorage.setItem("deviceId", deviceUniqueId);
  }

  return deviceUniqueId;
};

export { getDeviceUniqueId };
