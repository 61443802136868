import ReactGA from "react-ga4";
import axios from "axios";
import qs from "qs";
import { isEmpty, uniqBy } from "lodash";
import { Environments, getEnvironment } from "./environment";
import { getDefaultRequestHeaders } from "./getDefaultRequestHeaders";
import { getDeviceUniqueId } from "./getDeviceUniqueId";
import { GLOBAL_WATCH_FEED_VIDEO_COUNT } from "./constants";
import { trackSharedLink } from "./trackSharedLink";

const postDataAsync = async (path, data, isJson, bearerToken) => {
  const requestHeaders = getDefaultRequestHeaders(bearerToken);
  let body;

  if (isJson) {
    body = JSON.stringify(data);
  } else if (isEmpty(data)) {
    body = {};
  } else {
    const alreadyFormData = data instanceof FormData;
    const formData = alreadyFormData ? data : new FormData();
    if (!alreadyFormData) {
      Object.keys(data).forEach(key => formData.append(key, data[key]));
    }
    body = formData;
  }

  try {
    const response = await axios.post(path, body, {
      headers: requestHeaders
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const sendReferralData = (referral) => {
  const requestHeaders = getDefaultRequestHeaders();
  const requestUrl = "/api/v2/trackers/share";
  const data = {};
  const config = {
    headers: requestHeaders
  };

  if (referral.resourceId) {
    data.resourceId = referral.resourceId;
  }
  if (referral.resourceType) {
    data.resourceType = referral.resourceType;
  }
  if (referral.referrerCode) {
    data.referrerCode = referral.referrerCode;
  }

  // track share event in GA
  ReactGA.event({
    category: "Social",
    action: "TRACK_SHARING_LINK",
    label:
      "Identifier: " +
      referral.resourceId +
      ", Type: " +
      referral.resourceType +
      ", Referrer: " +
      referral.referrerCode
  });

  // send share data to api
  axios.post(requestUrl, JSON.stringify(data), config).catch((error) => {
    console.log(error);
  });
};

const setReviewRead = (reviewId, watchedDuration) => {
  if (!reviewId) {
    return;
  }

  const deviceId = getDeviceUniqueId();
  const requestHeaders = getDefaultRequestHeaders();
  const requestUrl = `/api/v2/reviews/${reviewId}/reads/${deviceId}`;
  const data = {};
  const config = {
    headers: requestHeaders
  };

  if (watchedDuration) {
    data.watchedDuration = Number(watchedDuration);
  }

  axios
    .put(requestUrl, JSON.stringify(data), config)
    // eslint-disable-next-line no-unused-vars
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    });
};

const fetchData = (path, callback, id) => {
  const requestHeaders = getDefaultRequestHeaders();
  axios
    .get(path, {
      headers: requestHeaders
    })
    .then((response) => {
      callback(response.data, id);
    })
    .catch((error) => {
      console.log(error);
    });
};

const fetchDataAsync = async (path, bearerToken) => {
  const requestHeaders = getDefaultRequestHeaders(bearerToken);
  try {
    const response = await axios.get(path, {
      headers: requestHeaders
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const fetchGlobalReviewFeed = async (limit = null, isNearMe = false) => {
  const reviewLimit = limit ?? GLOBAL_WATCH_FEED_VIDEO_COUNT;

  let url = `/api/v4/global/watch?limit=${reviewLimit}${isNearMe ? "" : "&result_type=global"}`;

  const feedResponse = await fetchDataAsync(url);

  if (!feedResponse.results) {
    return [];
  }

  feedResponse.results.map((result) => {
    result.idx = result.video["clm_id"];
    result.video.idx = result.video["clm_id"];
  });

  return {
    ...feedResponse,
    result_type: isNearMe ? "near_me" : feedResponse.result_type
  };
};

const fetchCityFeed = async (citySlug) => {
  try {
    const cityMeta = await fetchDataAsync(
      `/api/v2/routes/${citySlug}?target_type=city`
    );
    const cityFeedResponse = await fetchDataAsync(
      `/api/v2/cities/${cityMeta.targetId}/feed?limit=3`
    );
    if (!cityFeedResponse?.content) {
      return [];
    }

    const feedResults = cityFeedResponse.content;
    feedResults.map((result) => (result.idx = result.video["clm_id"]));

    return feedResults;
  } catch (error) {
    console.log({ error });
  }
};

const fetchCityProfile = async (citySlug, limit = 4) => {
  try {
    const cityMeta = await fetchDataAsync(
      `/api/v2/routes/${citySlug}?target_type=city`
    );
    const cityFeedResponse = await fetchDataAsync(
      `/api/v2/cities/${cityMeta.targetId}/feed?limit=${limit}`
    );

    if (!cityFeedResponse?.content) {
      const { clm_city, clm_state, clm_country, clm_lat, clm_lon, clm_service_id } = cityMeta.target;

      return {
        displayName: clm_city,
        state: clm_state,
        country: clm_country,
        latitude: clm_lat,
        longitude: clm_lon,
        next_start: cityFeedResponse.next_start,
        id: cityMeta.targetId,
        content: [],
        classifications: [],
        serviceId: clm_service_id
      };
    }

    return cityFeedResponse;
  } catch (error) {
    console.log({ error });
  }
};

const fetchCreatorDetails = async (userSlug) => {
  try {
    const { targetId, target } = await fetchDataAsync(
      `/api/v2/routes/${userSlug}?target_type=user`
    );

    if (!targetId && !target?.clm_id) {
      return {};
    }

    return await fetchDataAsync(`/api/v2/users/${targetId || target.clm_id}`);
  } catch (error) {
    console.log({error});
  }
}

const fetchUserReviewsGroupedByCity = async (userId) => {
  try {
    const { data } = await fetchDataAsync(`/api/v2/users/${userId}/reviews/by-city`) || {};
    return data;
  } catch (error) {
    console.log({error});
  }
}

const fetchLocationProfile = async (locationIdentifier, reviewId = null) => {
  try {
    let locationId = parseInt(locationIdentifier);
    if (isNaN(locationIdentifier)) {
      const locationRouteMeta = await fetchDataAsync(
        `/api/v2/routes/${locationIdentifier}?target_type=location`
      );
      locationId = locationRouteMeta?.targetId;
    }

    const metaPromise = fetchDataAsync(`/api/v2/locations/${locationId}`);

    let videosPromise;
    if (reviewId) {
      trackSharedLink(reviewId);
      videosPromise = fetchDataAsync(`/api/v2/reviews/${reviewId}`);
    } else {
      trackSharedLink(locationId);
      videosPromise = fetchDataAsync(
        `/api/v3/locations/${locationId}/videos?per_page=15`
      );
    }

    let [metaResponse, videosResponse] = await Promise.all([
      metaPromise,
      videosPromise
    ]);

    if (reviewId) {
      videosResponse = {
        data: [videosResponse]
      };
    }

    const videos = videosResponse.data;
    videos.map((video) => (video.idx = video.video["clm_id"]));

    return {
      metaResponse,
      videos
    };
  } catch (error) {
    console.log({ error });
  }
  return null;
};

const fetchLocationProfileByReviewId = async (reviewId) => {
  if (!reviewId) {
    return null;
  }

  try {
    trackSharedLink(reviewId);
    const reviewResponse = await fetchDataAsync(`/api/v2/reviews/${reviewId}`);
    const locationId = reviewResponse?.location?.clm_id;
    const metaResponse = locationId
      ? await fetchDataAsync(`/api/v2/locations/${locationId}`)
      : {};

    return {
      videos: [reviewResponse],
      metaResponse
    };
  } catch (error) {
    console.log({ error });
  }
};

const fetchLocationData = async (locationId) => {
  if (!locationId) {
    return null;
  }

  try {
    return await fetchDataAsync(`/api/v2/locations/${locationId}`);
  } catch (error) {
    console.log({ error });
  }
};

const fetchLocationTips = async (locationId) => {
  if (!locationId) {
    return null;
  }

  try {
    const response = await fetchDataAsync(`/api/v2/locations/${locationId}/tips`);
    return response?.data || [];
  } catch (error) {
    console.log({ error });
  }
};

const fetchRelatedLocations = async (locationId) => {
  if (!locationId) {
    return null;
  }

  try {
    // Note: use_cache=1&use_only_cache=1 query params are used to fetch data from our database cache only
    // instead of making a new request to Foursquare by our BE.
    return await fetchDataAsync(`/api/v2/locations/${locationId}/related?use_cache=1&use_only_cache=1`);
  } catch (error) {
    console.log({ error });
  }
};

const fetchCollectionData = async (collectionId) => {
  try {
    const collectionResponse = await fetchDataAsync(
      `/api/v2/collections/${collectionId}`
    );
    collectionResponse.content.map(
      (review) => (review.idx = review.video["clm_id"])
    );

    trackSharedLink(collectionId);
    return {
      videos: collectionResponse.content,
      collectionTitle: collectionResponse.collection["clm_name"]
    };
  } catch (error) {
    console.log({ error });
  }
};

const fetchUserCollectionData = async (userId, collectionId) => {
  try {
    const collectionResponse = await fetchDataAsync(
      `/api/user/collections/${userId}?single=${collectionId}`
    );
    const collectionDetail = collectionResponse[collectionId];
    collectionDetail.reviews.map(
      (review) => (review.idx = review.video["clm_id"])
    );

    trackSharedLink(collectionId);
    return {
      videos: collectionDetail.reviews,
      collectionTitle: collectionDetail.name,
      authorName: collectionDetail.reviews[0].author["clm_username"]
    };
  } catch (error) {
    console.log({ error });
  }
};

const fetchGlobalCuisines = async (options) => {
  const {
    page = 1,
    postsPerPage = 10,
    isPinned = false,
    sortByCount = false
  } = options;

  try {
    const response = await fetchDataAsync(
      `/api/v3/classifications/cuisines?page=${page}&per_page=${postsPerPage}&pinned_first=true`
    );

    const cuisines = isPinned ? response.filter(cuisine => cuisine.clm_pinned === "1") : response;
    return sortByCount
      ? cuisines.sort((a, b) => Number(b.clm_count) - Number(a.clm_count))
      : cuisines;
  } catch (error) {
    console.log({ error });
  }
}

const fetchGlobalPracticalCategories = async (options) => {
  const {
    page = 1,
    postsPerPage = 10,
    sortByCount = false
  } = options;

  try {
    const response = await fetchDataAsync(
      `/api/v3/classifications/practical-categories?page=${page}&per_page=${postsPerPage}`
    );

    return sortByCount
      ? response.sort((a, b) => Number(b.clm_count) - Number(a.clm_count))
      : response;
  } catch (error) {
    console.log({ error });
  }
}

const fetchProductDefaultVideo = async reviewId => {
  const defaultReviewId = getEnvironment() === Environments.Production
    ? 448782
    : 148734

  try {
    return await fetchDataAsync(`/api/v2/reviews/${reviewId ?? defaultReviewId}`);
  } catch (error) {
    console.log({ error });
  }
}

/**
 * As of 07/09/2024, we currently only use wonder search for biz - claim business with very few custom query params.
 * We can extend this function when we need to use wonder search for other purposes.
 * */
const performWonderSearch = async (options) => {
  const {
    searchTerm,
    include,
    limit
  } = options || {};

  let filterParams = {
    search_type: "global",
    term: encodeURIComponent(searchTerm)
  };

  if (include) {
    filterParams = {
      ...filterParams,
      include: include.join(",")
    };
  }

  if (limit) {
    filterParams = {
      ...filterParams,
      limit
    };
  }

  const queryParams = qs.stringify(filterParams);
  const requestUrl = `/api/v2/search/wonder-search?${queryParams}`;

  try {
    return await fetchDataAsync(requestUrl);
  } catch (error) {
    console.log({ error });
  }
}

const fetchBusinessVideos = async (
  businessId,
  nextPage = 1,
  perPage = 20
) => {
  if (!businessId) {
    return null;
  }

  try {
    const videosResponse =
      await fetchDataAsync(`/api/v3/locations/${businessId}/videos?page=${nextPage}&per_page=${perPage}`);
    const { has_more, page, number_of_pages } = videosResponse?.pagination_data || {};

    return {
      data: uniqBy(videosResponse?.data || [], "video.clm_id"),
      hasMore: has_more === "1",
      page: Number(page),
      numberOfPages: Number(number_of_pages)
    }
  } catch (error) {
    console.log({ error });
  }
}

const fetchBusinessData = async (
  businessId,
  nextPage = 1,
  perPage = 20
) => {
  if (!businessId) {
    return null;
  }

  try {
    const metaPromise = await fetchLocationData(businessId);
    const videosPromise = await fetchBusinessVideos(businessId, nextPage, perPage);

    const [meta, videos] = await Promise.all([
      metaPromise,
      videosPromise
    ]);

    return { meta, videos }
  } catch (error) {
    console.log({ error });
  }
}

const fetchMe = async currentUserToken => {
  try {
    return await fetchDataAsync("/api/user/me", currentUserToken);
  } catch (error) {
    console.log({ error });
  }
}

const fetchUserDetails = async (userId) => {
  if (!userId) {
    return null;
  }

  try {
    return await fetchDataAsync(`/api/v2/users/${userId}`);
  } catch (error) {
    console.log({ error });
  }
}

export {
  fetchBusinessData,
  fetchBusinessVideos,
  fetchCityFeed,
  fetchCityProfile,
  fetchCollectionData,
  fetchCreatorDetails,
  fetchData,
  fetchDataAsync,
  fetchGlobalCuisines,
  fetchGlobalPracticalCategories,
  fetchGlobalReviewFeed,
  fetchLocationData,
  fetchLocationProfile,
  fetchLocationProfileByReviewId,
  fetchLocationTips,
  fetchMe,
  fetchProductDefaultVideo,
  fetchRelatedLocations,
  fetchUserCollectionData,
  fetchUserDetails,
  fetchUserReviewsGroupedByCity,
  performWonderSearch,
  postDataAsync,
  sendReferralData,
  setReviewRead
};
