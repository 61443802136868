import queryString from "query-string";
import { sendReferralData } from "./api";

export const trackSharedLink = (identifier, shareTypeOverride = null) => {
  const queryParams = queryString.parse(window.location.search);
  if (!queryParams.referrer || !identifier) {
    return;
  }

  const referralData = {
    resourceId: identifier,
    resourceType: shareTypeOverride ?? queryParams.share_type,
    referrerCode: queryParams.referrer
  };

  sendReferralData(referralData);
};
