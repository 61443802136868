import React, { useCallback, forwardRef } from "react";
import { isMobile as isMobileDevice } from "react-device-detect";
import atmosfyNewLogo from "../../images/atmosfy-new-logo.svg";
import { ATMOSFY_ONE_LINK } from "../../utils/constants";
import styles from "./Navigation.module.css";

const Navigation = forwardRef(({
  visibleOnMobile,
  compact
}, ref) => {
  const isMobileWidth = window.innerWidth <= 768;

  const handleClickCta = () => window.open(ATMOSFY_ONE_LINK);

  const handleClickLogo = useCallback(() => {
    if (isMobileDevice && !visibleOnMobile) {
      return;
    }

    ref?.pageRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });

    if (!ref) {
      window.location.href = window.location.origin;
    }
  }, [ref]);

  const handleNavLinkClick = useCallback((ref, elementId, block) => {
    if (!ref?.current) {
      window.location.href = `${window.location.origin}${elementId ? `#${elementId}` : ""}`;
      return;
    }

    ref.current.scrollIntoView({
      behavior: "smooth",
      block: block || "center"
    });
  }, []);

  if (isMobileDevice && !visibleOnMobile) {
    return null;
  }

  if (compact) {
    return (
      <div className={styles.header}>
        <img
          src={atmosfyNewLogo}
          alt="Atmosfy logo"
          className={styles.logo}
          onClick={handleClickLogo}
          loading="lazy"
        />
      </div>
    );
  }

  return (
    <div className={styles.header}>
      <img
        src={atmosfyNewLogo}
        alt="Atmosfy logo"
        className={styles.logo}
        onClick={handleClickLogo}
        loading="lazy"
      />
      {!isMobileWidth && (
        <nav className={styles.nav}>
          <ul>
            <li onClick={() => handleNavLinkClick(ref?.productRef, "product")}>
              Product
            </li>

            <li onClick={() => handleNavLinkClick(ref?.bizRef, "biz")}>
              Businesses
            </li>

            <li onClick={() => handleNavLinkClick(ref?.brandsRef, "brands")}>
              Brands
            </li>

            <li onClick={() => handleNavLinkClick(ref?.partnersRef, "partners")}>
              Partners
            </li>

            <li onClick={() => window.open("/creators")}>
              Creators
            </li>
          </ul>
        </nav>
      )}

      <button className={styles.buttonGetAtmosfy}>
        <div onClick={handleClickCta}>Get Atmosfy</div>
      </button>
    </div>
  );
});

export default Navigation;
