import { createContext, useState, useCallback, useMemo } from "react";

export const HeaderContext = createContext({});

export const HeaderContextProvider = ({ children }) => {
  const [titleContent, setTitleContentInternal] = useState(null);
  const [logoContent, setLogoContent] = useState(null);
  const [headerClassName, setHeaderClassName] = useState(null);
  const [supportsTitleContent, setSupportsTitleContent] = useState(true);

  const setTitleContent = useCallback(
    (content) => {
      if (!supportsTitleContent) {
        return;
      }

      setTitleContentInternal(content);
    },
    [supportsTitleContent]
  );

  const value = useMemo(() => {
    return {
      titleContent,
      logoContent,
      headerClassName,
      setTitleContent,
      setLogoContent,
      setHeaderClassName,
      setSupportsTitleContent
    };
  }, [headerClassName, logoContent, setTitleContent, titleContent]);

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};
