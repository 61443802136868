import { useContext, useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { ModalContext } from "./ModalContext";

const Modal = ({ content }) => {
  const { isActive, closeModal } = useContext(ModalContext);
  const [topValue, setTopValue] = useState(0);
  useEffect(() => {
    if (document) {
      document.body.style.overflow = isActive ? "hidden" : "auto";
    }
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      setTopValue(Math.floor(window.scrollY));
    }
  }, [isActive]);

  if (!isActive) {
    return null;
  }

  return (
    <div
      className={styles.modal}
      style={{
        top: topValue
      }}
    >
      <div className={styles.background} onClick={closeModal} />

      <div className={styles.content}>{content}</div>
    </div>
  );
};

export default Modal;
