import { createContext, useState, useCallback, useMemo, useEffect } from "react";
import { signIn as authSignIn, signOut as authSignOut, signUp as authSignUp } from "utils/auth";
import { Environments, getEnvironment } from "utils/environment";

export const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [user, setUser] = useState(null);
  const sessionUser = sessionStorage.getItem("appUser");
  const isProd = getEnvironment() === Environments.Production

  useEffect(() => {
    if (sessionUser) {
      setUser(JSON.parse(sessionUser));
    }
  }, []);

  useEffect(() => {
    setAuthenticated(!!user?.user_id)
  }, [user]);

  const signIn = useCallback(async provider => {
    try {
      const result = await authSignIn(provider);

      if (result?.appUser) {
        setUser({
          ...result?.appUser,
          email: result?.user?.email
        })

        const { user_id, user_name, user_level, is_internal, vanity_url, avatarUrl, businesses } = result.appUser || {};
        sessionStorage.setItem("appUser", JSON.stringify({
          user_id,
          username: user_name,
          user_level,
          is_internal,
          vanity_url,
          avatarUrl,
          email: result?.user?.email,
          businesses
        }));
      }

      if (result?.error === "No app user found") {
        setAuthUser(result?.user);
        return Promise.reject(result.error);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }, []);

  const signUp = useCallback(async (customProfileData, onSuccess, onError) => {
    const { displayName, email, photoURL, uid, providerData, phoneNumber } = authUser || {};
    const { providerId: providerType, uid: providerId } = providerData[0] || {};
    const phone = phoneNumber ? phoneNumber.replace("+", "") : null;

    const profileData = {
      displayName,
      email,
      isNewUser: true,
      makeConcierge: false,
      providerId,
      providerType,
      serviceId: uid,
      ...(phone && { phoneNumber: phone }),
      ...(photoURL && { photoUrl: photoURL }),
      ...customProfileData
    };

    try {
      const result = await authSignUp(profileData);
      if (result?.appUser) {
        setUser({
          ...result?.appUser,
          email: profileData.email
        });

        const { user_id, user_name, user_level, is_internal, vanity_url, avatarUrl, businesses } = result.appUser || {};
        sessionStorage.setItem("appUser", JSON.stringify({
          user_id,
          username: user_name,
          user_level,
          is_internal,
          vanity_url,
          avatarUrl,
          email: profileData.email,
          businesses
        }));
      }

      onSuccess?.(result);
    } catch (error) {
      onError?.(error);
      return Promise.reject(error);
    }
  }, [authUser]);

  const signOut = useCallback(() => {
    authSignOut();
    setAuthenticated(false);
    setUser(null);
    sessionStorage.removeItem("appUser");
  }, []);

  const value = useMemo(() => {
    return {
      authenticated,
      user,
      setUser,
      authUser,
      signIn,
      signOut,
      signUp
    };
  }, [authenticated, user, authUser]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
