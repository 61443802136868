const Environments = {
  Local: "local",
  Production: "production",
  Sandbox: "sandbox",
  Unknown: "unknown"
};

const getEnvironment = () => {
  const url = window.location.href;
  if (url.indexOf("sandbox.atmosfy.io") >= 0) {
    return Environments.Sandbox;
  } else if (url.indexOf("localhost") >= 0) {
    return Environments.Local;
  } else if (url.indexOf("atmosfy.io") >= 0) {
    return Environments.Production;
  }

  return Environments.Unknown;
};

export { getEnvironment, Environments };
