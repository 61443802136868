import {
  appleSignInWithPopup,
  facebookSignInWithPopup,
  googleSignInWithPopup,
  getCurrentUserToken,
  signOut
} from "./firebase";
import { fetchMe, fetchUserDetails, postDataAsync } from "../api";

export const PROVIDERS = {
  GOOGLE: "google",
  FACEBOOK: "facebook",
  APPLE: "apple"
}

export const AUTH_PROVIDERS = Object.values(PROVIDERS);

const signIn = async (provider) => {
  let authWithProvider = null;

  switch (provider) {
    case PROVIDERS.GOOGLE:
      authWithProvider = googleSignInWithPopup;
      break;
    case PROVIDERS.FACEBOOK:
      authWithProvider = facebookSignInWithPopup;
      break;
    case PROVIDERS.APPLE:
      authWithProvider = appleSignInWithPopup;
      break;
    default:
      throw new Error(`Provider ${provider} not supported`);
  }

  try {
    const { user } = await authWithProvider() || {};
    const currentUserToken = await getCurrentUserToken();

    if (!user) {
      return Promise.reject(new Error("No user found"));
    }

    const { displayName, email, photoURL, uid, providerData, phoneNumber } = user || {};
    const { providerId: providerType, uid: providerId } = providerData[0];
    const phone = phoneNumber ? phoneNumber.replace("+", "") : null;

    const postData = {
      displayName,
      email,
      phoneNumber: phone,
      photoUrl: photoURL,
      providerId,
      providerType,
      serviceId: uid
    }
    
    const appUser = await postDataAsync("/api/user/me", postData, false, currentUserToken)

    // If no app user is found, we will kick off the signup flow
    if (appUser.status === "user_not_found") {
      return Promise.resolve({ error: "No app user found", user });
    }

    if (appUser?.user_id) {
      const userDetails = await fetchUserDetails(appUser.user_id);
      appUser.businesses = userDetails?.business_dashboard_locations || [];
    }

    return { appUser, user };
  } catch (error) {
    return Promise.reject(error);
  }
}

const signUp = async (profileData) => {
  try {
    const requestUrl = "/api/user/save";
    const currentUserToken = await getCurrentUserToken();

    const result = await postDataAsync(requestUrl, profileData, false, currentUserToken);
    
    if (result?.status !== "valid") {
      return Promise.reject(result);
    }

    const appUser = await fetchMe(currentUserToken);
    return { appUser };
  } catch (error) {
    return Promise.reject(error);
  }
}

const validateUsername = async (username) => {
  try {
    return await postDataAsync("/api/user/valid", { username });
  } catch (error) {
    return Promise.reject(error);
  }
}

export { signIn, signUp, signOut, validateUsername }
