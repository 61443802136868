import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { ModalContext } from "components/Modal/ModalContext";
import * as API from "utils/api";
import { APP_MODAL_REMOVAL, LOCATION_PROFILE, POSTHOG_WAIT_TIME, VARIANT } from "utils/featureFlags";

const LocationProfileContext = createContext(undefined);

export const useLocationProfile = () => {
  const context = useContext(LocationProfileContext);
  if (context === undefined) {
    throw new Error(
      "useLocationProfile must be used within a LocationProfileProvider"
    );
  }
  return context;
};

const LocationProfileProvider = ({ children }) => {
  const routeParams = useParams();
  const { isActive, openModal } = useContext(ModalContext);

  const [locationData, setLocationData] = useState(null);
  const [feedData, setFeedData] = useState(null);
  const [defaultVideo, setDefaultVideo] = useState(null);
  const [millis, setMillis] = useState(0);

  const locationProfileTest = useFeatureFlagVariantKey(LOCATION_PROFILE);

  const appModalRemovalTest =
    useFeatureFlagVariantKey(APP_MODAL_REMOVAL);

  const shouldCheckForModal = useMemo(() => {
    return appModalRemovalTest || millis > POSTHOG_WAIT_TIME;
  }, [appModalRemovalTest, millis]);

  useEffect(() => {
    if (millis > POSTHOG_WAIT_TIME) {
      return;
    }

    const interval = setInterval(() => {
      setMillis(millis + 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [millis]);

  useEffect(() => {
    const { locationIdentifier, reviewId } = routeParams || {};
    if (!locationIdentifier && !reviewId) {
      return;
    }

    const getLocationData = async () => {
      const { metaResponse, videos } = locationIdentifier
        ? await API.fetchLocationProfile(locationIdentifier, reviewId)
        : await API.fetchLocationProfileByReviewId(reviewId);

      setLocationData(metaResponse);
      setFeedData(videos);
    };

    const getProductDefaultVideo = async () => {
      const video = await API.fetchProductDefaultVideo();
      setDefaultVideo({
        ...video,
        isDefault: true
      });
    }

    getLocationData().catch((error) => console.log({ error }));
    getProductDefaultVideo().catch((error) => console.log({ error }));
  }, [routeParams.locationIdentifier, routeParams.reviewId]);

  useEffect(() => {
    if (
      !isMobile ||
      isActive ||
      !shouldCheckForModal ||
      appModalRemovalTest === VARIANT ||
      locationProfileTest === VARIANT
    ) {
      return;
    }

    const timeoutId = setTimeout(() => {
      openModal();
    }, 4000);

    return () => clearTimeout(timeoutId);
  }, [openModal, isActive, shouldCheckForModal, appModalRemovalTest, locationProfileTest]);

  const context = useMemo(() => {
    return {
      locationData,
      feedData,
      defaultVideo
    };
  }, [feedData, locationData, defaultVideo]);

  return (
    <LocationProfileContext.Provider value={context}>
      {children}
    </LocationProfileContext.Provider>
  );
};

export default LocationProfileProvider;
