import { createContext, useState, useCallback, useMemo } from "react";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { ATMOSFY_ONE_LINK } from "utils/constants";
import { getOneLinkWithClickSource } from "utils/getOneLink";
import useWindowDimensions from "hooks/useWindowDimensions";

export const ModalContext = createContext({});

export const ModalContextProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const { isMobile } = useWindowDimensions();

  const hasSeenModal = sessionStorage.getItem("app_modal");

  const openModal = useCallback((
    allowBypass = false,
    clickSource = null,
    forceBypass = false
  ) => {
    if ((allowBypass && hasSeenModal) || forceBypass || !isMobile) {
      window.open(getOneLinkWithClickSource(clickSource), "_blank");
      return;
    }

    sessionStorage.setItem("app_modal", "true");
    setIsActive(true);

    ReactGA.event({
      category: "Modal",
      action: "Opened modal window"
    });
    ReactPixel.trackCustom("MW_WATCH_Opens_Modal_Window");
  }, [hasSeenModal, isMobile]);

  const closeModal = useCallback(() => {
    setIsActive(false);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        isActive,
        openModal,
        closeModal
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
