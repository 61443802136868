import * as React from "react";
import { isMobile } from "react-device-detect";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Modal from "../../components/Modal/Modal";
import QRCode from "../../components/QRCode/QRCode";
import { useContext } from "react";
import { ModalContext } from "../../components/Modal/ModalContext";

const MarketingLayout = ({ children }) => {
  const { closeModal } = useContext(ModalContext);
  const { isMobile: isMobileDimensions } = useWindowDimensions();

  return (
    <main className="App">
      {!isMobile && !isMobileDimensions && (
        <Modal content={<QRCode closeModal={closeModal} />} />
      )}
      {children}
    </main>
  );
};

export default MarketingLayout;
