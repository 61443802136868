import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import omit from "lodash/omitBy";
import { AuthContext } from "components/Auth/AuthContext";
import * as API from "utils/api";

const BusinessDashboardContext = createContext(undefined);

export const useBusinessDashboard = () => {
  const context = useContext(BusinessDashboardContext);
  if (context === undefined) {
    throw new Error(
      "useBusinessDashboard must be used within a BusinessDashboardProvider"
    );
  }
  return context;
}

const BusinessDashboardProvider = ({ children }) => {
  const [businessId, setBusinessId] = useState(null);
  const [businessMeta, setBusinessMeta] = useState({});
  const [businessVideos, setBusinessVideos] = useState([]);
  const [businessVideosPagination, setBusinessVideosPagination] = useState({});
  const [requireOnboarding, setRequireOnboarding] = useState(false);

  const { user, authenticated } = useContext(AuthContext);

  const getMoreBusinessVideos = useCallback(async nextPage => {
    if (!businessId) {
      return;
    }

    const videos= await API.fetchBusinessVideos(businessId, nextPage);
    setBusinessVideos(videos?.data);
    setBusinessVideosPagination(omit(videos, "data"));
  }, [businessId]);

  const getBusinessData = useCallback(async () => {
    const { meta, videos } = await API.fetchBusinessData(businessId);
    setBusinessMeta(meta);
    setBusinessVideos(videos?.data);
    setBusinessVideosPagination(omit(videos, "data"));
  }, [businessId])

  useEffect(() => {
    if (!authenticated) {
      setBusinessId(null);
      setBusinessMeta({});
      setBusinessVideos([]);
      setBusinessVideosPagination({});
      setRequireOnboarding(false);
    }
  }, [authenticated]);

  useEffect(() => {
    if (!user?.businesses?.length) {
      setRequireOnboarding(true);
      return;
    }

    setBusinessId(user?.businesses?.[0]?.clm_id);
    setRequireOnboarding(false);
  }, [user?.businesses]);

  useEffect(() => {
    if (!businessId) {
      return;
    }

    getBusinessData();
  }, [businessId]);

  return (
    <BusinessDashboardContext.Provider
      value={{
        businessMeta,
        businessVideos,
        businessVideosPagination,
        getMoreBusinessVideos,
        setBusinessId,
        requireOnboarding
      }}
    >
      {children}
    </BusinessDashboardContext.Provider>
  );
};

export default BusinessDashboardProvider;